class EthersServiceProvider {
  currentAccount;

  constructor() {}

  async setCurrentAccount(address) {
    this.currentAccount = address;
  }
}

const ethersServiceProvider = new EthersServiceProvider();

export default ethersServiceProvider;
