import { Avatar, Typography, useMediaQuery, useTheme } from "@mui/material";
import {
  Assessment,
  Discount,
  Fastfood,
  FoodBank,
  Info,
  LiveHelp,
  Money,
  Person,
  Pool,
  RequestQuote,
  Savings,
  SettingsApplications,
  Smartphone,
} from "@mui/icons-material";
import { useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import Sidebar from "./Sidebar";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  tab: {
    width: "100%",
    color: "#ffff",
    textAlign: "left",
    marginBottom: 0,
    marginTop: 10,
    padding: "10px",
    fontSize: 18,
    fontWeight: 500,
    cursor: "pointer",
    borderRadius: 8,
  },
  icon: {
    marginRight: 10,
  },
  active: {
    background: "#000",
    color: "#3c83c2",
    textAlign: "left",
    marginBottom: 0,
    marginTop: 10,
    padding: "10px",
    fontSize: 20,
    fontWeight: 500,
    cursor: "pointer",
    borderRadius: 8,
  },
}));

const Leftbar = ({ drawer, setDrawer }) => {
  const classes = useStyles();
  const location = useLocation();
  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <div
      className="d-flex flex-column ps-3 pe-3 pt-2 pt-md-4"
      style={{ background: "#212121", height: "100%", width: md && "100vw" }}
    >
      <div className="d-flex justify-content-md-center mb-3">
        <Avatar
          variant="square"
          src="https://assets.coingecko.com/coins/images/19696/large/Thumbnail_-_500_px_-_Black.png?1635751681"
          sx={{
            height: md ? 50 : 75,
            width: md ? 50 : 75,
            marginLeft: md && "20px",
          }}
        />
      </div>
      <Link to="/">
        <div
          className={`${
            location.pathname === "/" ? classes.active : classes.tab
          }`}
          style={{ fontSize: "16px" }}
          onClick={() => drawer && setDrawer(false)}
        >
          <Assessment className={classes.icon} />
          Anaytics
        </div>
      </Link>

      <Link to="/users">
        <div
          className={`${
            location.pathname.slice(0, 6) === "/users" ? classes.active : classes.tab
          }`}
          style={{ fontSize: "16px" }}
          onClick={() => drawer && setDrawer(false)}
        >
          <Person className={classes.icon} />
          User Details
        </div>
      </Link>

      <Link to="/vault">
        <div
          className={`${
            location.pathname === "/vault" ? classes.active : classes.tab
          }`}
          style={{ fontSize: "16px" }}
          onClick={() => drawer && setDrawer(false)}
        >
          <Savings className={classes.icon} />
          Vault
        </div>
      </Link>
      <Link to="/dishes">
        <div
          className={`${
            location.pathname === "/dishes" ? classes.active : classes.tab
          }`}
          style={{ fontSize: "16px" }}
          onClick={() => drawer && setDrawer(false)}
        >
          <Fastfood className={classes.icon} />
          Release Dishes
        </div>
      </Link>
      <Link to="/app">
        <div
          className={`${
            location.pathname === "/app" ? classes.active : classes.tab
          }`}
          style={{ fontSize: "16px" }}
          onClick={() => drawer && setDrawer(false)}
        >
          <Smartphone className={classes.icon} />
          App Dashboard
        </div>
      </Link>
      <Link to="/withdraw-requests">
        <div
          className={`${
            location.pathname === "/withdraw-requests"
              ? classes.active
              : classes.tab
          }`}
          style={{ fontSize: "16px" }}
          onClick={() => drawer && setDrawer(false)}
        >
          <RequestQuote className={classes.icon} />
          Withrawals
        </div>
      </Link>
      <Link to="/coupons">
        <div
          className={`${
            location.pathname.slice(0, 8) === "/coupons"
              ? classes.active
              : classes.tab
          }`}
          style={{ fontSize: "16px" }}
          onClick={() => drawer && setDrawer(false)}
        >
          <Discount className={classes.icon} />
          Coupons
        </div>
      </Link>
    </div>
  );
};

export default Leftbar;
