import { makeStyles } from "@mui/styles";
import { useEffect, useState } from "react";
import { Box, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import { getDailyClaimsAndReferralsData } from "../../../actions/serverActions";
import ClaimChipsChart from "./ClaimChipsChart";
import DailyReferralsChart from "./DailyReferralsChart";
import ClaimNotificationsChart from "./ClaimNotificationsChart";
import ClocheClaimChart from "./ClocheClaimChart";
import DishClocheClaimChart from "./DishClocheClaimChart";
import GameUsersChart from "./GameUsersChart";
import DoubleBarChart from "./DoubleBarChart";
import RestaurantClaimChart from "./ClaimNotificationsChart";
import DailyCipherChart from "./DailyCipherChart";

const useStyles = makeStyles((theme) => ({
  card: {
    backgroundColor: "#0C0D11",
    marginTop: 20,
    marginBottom: 20,
    paddingTop: 20,
    paddingBottom: 10,
    paddingLeft: 20,
    paddingRight: 20,
    width: "100%",
    minHeight: 200,
    height: "100%",
    border: "1px solid #1b1d24",
    boxShadow: "0px 12px 24px rgba(0, 0, 0, 0.03)",
    borderRadius: 14,
    "&:hover": {
      boxShadow: "0px 24px 33px -9px #0000005C",
    },

    [theme.breakpoints.down("md")]: {
      height: "100%",
      width: "100%",
    },
  },
}));

const AllCharts = ({ usersData }) => {
  const classes = useStyles();
  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <div>
      {usersData && (
        <Box
          mt={3.5}
          className={classes.card}
          style={{
            backgroundImage: "linear-gradient(to right,#DADADA, #bdbdbd)",
          }}
        >
          <Grid container mb={6} spacing={3}>
            <Grid item md={6}>
              <ClaimChipsChart claimData={usersData} />
            </Grid>
            <Grid item md={6}>
              <DailyReferralsChart referralData={usersData} />
            </Grid>
            <Grid item md={6}>
              <ClocheClaimChart clocheData={usersData} />
            </Grid>
            <Grid item md={6}>
              <DishClocheClaimChart clocheDailyData={usersData} />
            </Grid>

            <Grid item md={6}>
              <GameUsersChart
                gameData={usersData.food5}
                dates={usersData.dates}
                type={"Food5"}
              />
            </Grid>
            <Grid item md={6}>
              <GameUsersChart
                gameData={usersData.foodfury}
                dates={usersData.dates}
                type={"FoodFury"}
              />
            </Grid>
            <Grid item md={6}>
              <DailyCipherChart graphData={usersData} />
            </Grid>
          </Grid>

          <Grid container mb={6} spacing={3}>
            <Grid item md={6}>
              <RestaurantClaimChart
                notificationsData={usersData.restaurantNoti}
                timeIndex={0}
                dates={usersData.dates}
                title="Restaurant Notifications(Sent/Opened) - 24 Hrs"
              />
            </Grid>
            <Grid item md={6}>
              <RestaurantClaimChart
                notificationsData={usersData.restaurantNoti}
                timeIndex={1}
                dates={usersData.dates}
                title="Restaurant Notifications(Sent/Opened) - 36 Hrs"
              />
            </Grid>
          </Grid>
          <Grid container mb={6} spacing={3}>
            <Grid item md={6}>
              <RestaurantClaimChart
                notificationsData={usersData.restaurantNoti}
                timeIndex={2}
                dates={usersData.dates}
                title="Restaurant Notifications(Sent/Opened) - 60 Hrs"
              />
            </Grid>
            <Grid item md={6}>
              <RestaurantClaimChart
                notificationsData={usersData.restaurantNoti}
                timeIndex={3}
                dates={usersData.dates}
                title="Restaurant Notifications(Sent/Opened) - 4 Days"
              />
            </Grid>
          </Grid>
          <Grid container mb={6} spacing={3}>
            <Grid item md={6}>
              <RestaurantClaimChart
                notificationsData={usersData.restaurantNoti}
                timeIndex={4}
                dates={usersData.dates}
                title="Restaurant Notifications(Sent/Opened) - 8 Days"
              />
            </Grid>
            <Grid item md={6}>
              <RestaurantClaimChart
                notificationsData={usersData.restaurantNoti}
                timeIndex={5}
                dates={usersData.dates}
                title="Restaurant Notifications(Sent/Opened) - 14 Days"
              />
            </Grid>
          </Grid>

          <Grid container mb={6} spacing={3}>
            <Grid item md={12}>
              <DoubleBarChart
                notificationsData={usersData.clocheUnlock}
                dates={usersData.dates}
                title="Cloche Notifications(Sent/Opened)"
              />
            </Grid>
          </Grid>
        </Box>
      )}
    </div>
  );
};

export default AllCharts;
