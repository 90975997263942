import Chart from "react-apexcharts";
import { Box, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { People, Group, TrendingUp } from "@mui/icons-material";
import { useEffect, useState } from "react";
import { getDailyClaimsAndreferralData } from "../../../actions/serverActions";

const useStyles = makeStyles((theme) => ({
  card: {
    backgroundColor: "#111214",
    marginTop: 20,
    marginBottom: 20,
    paddingTop: 20,
    paddingBottom: 10,
    paddingLeft: 20,
    paddingRight: 20,
    width: "100%",
    minHeight: 200,
    height: "100%",
    border: "1px solid #1b1d24",
    boxShadow: "0px 12px 24px rgba(0, 0, 0, 0.03)",
    borderRadius: 14,
    "&:hover": {
      boxShadow: "0px 24px 33px -9px #0000005C",
    },

    [theme.breakpoints.down("md")]: {
      height: "100%",
      width: "100%",
    },
  },
}));

const DailyReferralsChart = ({ referralData }) => {
  const classes = useStyles();
  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.down("md"));

  console.log("referralData", referralData);
  let chartData = {
    series: [
      {
        name: "Referrals",
        data: referralData ? referralData.referrals : [],
      },
    ],
    options: {
      chart: {
        id: "chartyear",
        height: 350,
        // color: "#000",
        zoom: {
          enabled: false,
        },
      },
      yaxis: {
        show: true,
        showAlways: true,
        labels: {
          show: true,
          align: "right",
          minWidth: 0,
          maxWidth: 160,
          style: {
            colors: ["#e5e5e5"],
            fontSize: "12px",
            fontFamily: "Helvetica, Arial, sans-serif",
            fontWeight: 400,
            cssClass: "apexcharts-yaxis-label",
          },
        },
        axisBorder: {
          show: true,
          color: "#e5e5e5",
          offsetX: 0,
          offsetY: 0,
        },

        title: {
          text: "Referrals Per Day",
          rotate: -90,
          style: {
            color: "#e5e5e5",
            fontSize: "12px",
            fontFamily: "Helvetica, Arial, sans-serif",
            fontWeight: 600,
            cssClass: "apexcharts-yaxis-title",
          },
        },
      },

      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "40%",
          endingShape: "rounded",
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "straight",
      },
      xaxis: {
        categories: referralData ? referralData.dates : [],
        labels: {
          show: true,
          rotate: 0,
          rotateAlways: false,
          hideOverlappingLabels: true,
          showDuplicates: false,
          trim: false,
          minHeight: undefined,
          maxHeight: 120,
          style: {
            colors: "#eeeeee",
            fontSize: "12px",
            fontFamily: "Helvetica, Arial, sans-serif",
            fontWeight: 400,
            cssClass: "apexcharts-xaxis-label",
          },
        },
      },

      colors: ["#e5e5e5", "#212121", "#212121"],
      title: {
        enabled: false,

        align: "center",
        style: {
          fontSize: "14px",
          fontWeight: "bold",
          color: "#e5e5e5",
        },
      },
      tooltip: {
        enabled: true,
        enabledOnSeries: undefined,
        shared: true,
        followCursor: false,
        intersect: false,
        inverseOrder: false,
        custom: undefined,
        fillSeriesColor: false,
        theme: false,
        style: {
          fontSize: "12px",
          fontFamily: undefined,
        },
        onDatasetHover: {
          highlightDataSeries: false,
        },
        x: {
          show: true,
          format: "dd MMM",
          formatter: undefined,
        },
        y: {
          formatter: undefined,
          title: {
            formatter: (seriesName) => seriesName,
          },
        },
        z: {
          formatter: undefined,
          title: "Size: ",
        },
        marker: {
          show: true,
        },
        items: {
          display: "flex",
        },
        fixed: {
          enabled: false,
          position: "topRight",
          offsetX: 0,
          offsetY: 0,
        },
      },
    },
  };

  return (
    <Box className={classes.card}>
      <Box
        width="100%"
        pt={2}
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
        position="relative"
      >
        <Box
          width="100%"
          display={"flex"}
          justifyContent={md ? "center" : "flex-start"}
          alignItems={"center"}
          pb={md ? 7 : 4}
        >
          <Box
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            mr={1}
            style={{
              backgroundColor: "#505961",
              height: 36,
              width: 36,
              borderRadius: 12,
            }}
          >
            <People />
          </Box>
          <Typography fontWeight={600} fontSize={18} color={"#f9f9f9"}>
            Daily Referrals
          </Typography>
        </Box>
        {/* <Box
          style={{
            position: "absolute",
            right: md ? -25 : -60,
            bottom: md && -10,
            display: "flex",
            gap: 5,
          }}
        >
          <input
            type="date"
            value={startDate}
            onChange={handleStartDateUpdate}
            style={{ width: 110, fontSize: 12 }}
          />
          <input
            type="date"
            value={endDate}
            onChange={handleEndDateUpdate}
            style={{ width: 110, fontSize: 12 }}
          />
        </Box> */}
      </Box>
      {referralData && (
        <Chart
          options={chartData.options}
          series={chartData.series}
          type="area"
          width={"100%"}
          height={300}
        />
      )}
    </Box>
  );
};

export default DailyReferralsChart;
