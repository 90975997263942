import Chart from "react-apexcharts";
import { Box, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Restaurant, Group, TrendingUp } from "@mui/icons-material";
import { useEffect, useState } from "react";
const useStyles = makeStyles((theme) => ({
  card: {
    backgroundColor: "#111214",
    marginTop: 20,
    marginBottom: 20,
    paddingTop: 20,
    paddingBottom: 10,
    paddingLeft: 20,
    paddingRight: 20,
    width: "100%",
    minHeight: 200,
    height: "100%",
    border: "1px solid #1b1d24",
    boxShadow: "0px 12px 24px rgba(0, 0, 0, 0.03)",
    borderRadius: 14,
    "&:hover": {
      boxShadow: "0px 24px 33px -9px #0000005C",
    },

    [theme.breakpoints.down("md")]: {
      height: "100%",
      width: "100%",
    },
  },
}));

const DoubleBarChart = ({ notificationsData, dates, title }) => {
  const classes = useStyles();
  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.down("md"));

  const [sent, setSent] = useState([]);
  const [received, setReceived] = useState([]);

  useEffect(() => {
    if (notificationsData) {
      let tempS = notificationsData.map((ele) => ele.sent);
      let tempR = notificationsData.map((ele) => ele.received);
      setSent(tempS);
      setReceived(tempR);
    }
  }, [notificationsData]);

  let chartData = {
    series: [
      {
        name: "Sent",
        data: sent,
      },
      {
        name: "Opened",
        data: received,
      },
    ],
    options: {
      chart: {
        type: "bar",
        height: 600,
      },
      plotOptions: {
        bar: {
          horizontal: false,
          dataLabels: {
            position: "top",
          },
        },
      },
      dataLabels: {
        enabled: true,
        offsetX: -6,
        style: {
          fontSize: "12px",
          colors: ["#000"],
        },
      },
      stroke: {
        show: false,
        width: 1,
        colors: ["#fff"],
      },
      tooltip: {
        shared: true,
        intersect: false,
      },
      yaxis: {
        labels: {
          show: true,
          rotate: 0,
          rotateAlways: false,
          hideOverlappingLabels: true,
          showDuplicates: false,
          trim: false,
          minHeight: undefined,
          maxHeight: 120,
          style: {
            colors: "#eeeeee",
            fontSize: "12px",
            fontFamily: "Helvetica, Arial, sans-serif",
            fontWeight: 400,
            cssClass: "apexcharts-xaxis-label",
          },
        },
      },
      xaxis: {
        categories: dates ? dates : [],
        labels: {
          show: true,
          rotate: 0,
          rotateAlways: false,
          hideOverlappingLabels: true,
          showDuplicates: false,
          trim: false,
          minHeight: undefined,
          maxHeight: 120,
          style: {
            colors: "#eeeeee",
            fontSize: "12px",
            fontFamily: "Helvetica, Arial, sans-serif",
            fontWeight: 400,
            cssClass: "apexcharts-xaxis-label",
          },
        },
      },
    },
  };

  return (
    <Box className={classes.card}>
      <Box
        width="100%"
        pt={2}
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
        position="relative"
      >
        <Box
          width="100%"
          display={"flex"}
          justifyContent={md ? "center" : "flex-start"}
          alignItems={"center"}
          pb={md ? 7 : 4}
        >
          <Box
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            mr={1}
            style={{
              backgroundColor: "#505961",
              height: 36,
              width: 36,
              borderRadius: 12,
            }}
          >
            <Restaurant />
          </Box>
          <Typography fontWeight={600} fontSize={18} color={"#f9f9f9"}>
            {title}
          </Typography>
        </Box>
      </Box>
      {notificationsData && (
        <Chart
          options={chartData.options}
          series={chartData.series}
          type="bar"
          width={"100%"}
          height={400}
        />
      )}
    </Box>
  );
};

export default DoubleBarChart;
