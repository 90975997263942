import React, { useEffect, useState } from "react";
import { Avatar, Box, Typography, useTheme } from "@mui/material";
import { Link } from "react-router-dom";

const Sidebar = ({ page, setPage }) => {
  const theme = useTheme();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [page]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      width="16vw"
      style={{
        position: "fixed",
        top: 0,
        bottom: 0,
        position: "-webkit-fixed",
        position: "fixed",
      }}
    >
      <Link
        to="/"
        style={{
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "#212121",
        }}
      >
        <Avatar
          variant="square"
          src="https://assets.coingecko.com/coins/images/19696/large/Thumbnail_-_500_px_-_Black.png?1635751681"
          sx={{
            height: 75,
            width: 75,
          }}
        />
      </Link>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        backgroundColor={page === "balances" ? "#e1489f" : "#212121"}
        mb={0.5}
        sx={{ cursor: "pointer", height: "100%" }}
        onClick={() => setPage("balances")}
      >
        <Typography variant="body2" fontWeight="bold">
          Balances
        </Typography>
      </Box>

      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{
          cursor: "pointer",
          height: "100%",
          backgroundColor: page === "releaseNew" ? "#e1489f" : "#212121",
        }}
        onClick={() => setPage("releaseNew")}
      >
        <Typography variant="body2" fontWeight="bold">
          Release New
        </Typography>
      </Box>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{ cursor: "pointer", height: "100%", backgroundColor: "#212121" }}
        // onClick={() => setPage("releaseNew")}
      >
        <Typography variant="body2" fontWeight="bold"></Typography>
      </Box>
    </Box>
  );
};

export default Sidebar;
