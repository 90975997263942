import { Box, Button } from "@mui/material";
import { makeStyles, useTheme } from "@mui/styles";
import { useEffect, useState } from "react";
import { getUserAddress } from "../actions/web3Actions";
import { useMetamask } from "../useMetamask";
import { Wallet } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

const Login = ({}) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const { loginWithMetamask, accountSC } = useMetamask();
  const [user, setUser] = useState(null);
  const [logInSuccess, setLogInSuccess] = useState(true);

  const admins = [
    "0xd85a647b27b2cc988caed8556c373d1b7e9567c3",
    "0xdd270eb989df9a239cdd9af235d9a295bd0d468a",
    "0xDD416AAA9B3E3aBDC015b9085b354B93140b8210",
    "0x9D7117a07fca9F22911d379A9fd5118A5FA4F448",
    "0xCC863109a4838637781b78e08F06743cE0148EEF",
  ];

  useEffect(() => {
    if (accountSC) {
      let adminExist = admins.find(
        (ele) => ele.toLowerCase() === accountSC.toLowerCase()
      );
      if (adminExist) {
        navigate("/");
        setLogInSuccess(true);
      } else setLogInSuccess(false);
    }
  }, [accountSC]);

  return (
    <Box
      style={{
        width: "100vw",
        height: "100vh",
        display: "grid",
        placeItems: "center",
        backgroundColor: "#000",
      }}
    >
      {logInSuccess ? (
        <Button
          sx={{
            width: "100%",
            maxWidth: 240,
            background: "#d1ff1a",
            border: "1px solid #000000",
            color: "#000",
            padding: "15px 25px",
            borderRadius: "20px",
            fontWeight: 500,
            textTransform: "uppercase",
            fontSize: 20,
            fontWeight: 600,
            [theme.breakpoints.down("sm")]: {
              padding: "10px 15px",
              maxWidth: 180,
              fontSize: 16,
            },
          }}
          variant="contained"
          onClick={loginWithMetamask}
        >
          Connect Wallet
        </Button>
      ) : (
        <Box
          style={{
            display: "grid",
            placeItems: "center",
            gap: 25,
            textAlign: "center",
          }}
        >
          <h3>
            Login Failed!
            <br />
            Please Login From An Admin Wallet.{" "}
          </h3>
          <Button
            sx={{
              width: "100%",
              maxWidth: 240,
              background: "#d1ff1a",
              border: "1px solid #000000",
              color: "#000",
              padding: "15px 25px",
              borderRadius: "20px",
              fontWeight: 500,
              textTransform: "uppercase",
              fontSize: 20,
              fontWeight: 600,
              [theme.breakpoints.down("sm")]: {
                padding: "10px 15px",
                maxWidth: 180,
                fontSize: 16,
              },
            }}
            variant="contained"
            onClick={() => window.location.reload()}
          >
            Refresh page
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default Login;
