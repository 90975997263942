import React, { useEffect, useMemo, useState } from "react";
import {
  IconButton,
  SwipeableDrawer,
  List,
  Divider,
  ListItem,
  ListItemText,
  AppBar,
  Toolbar,
  Avatar,
} from "@mui/material";
import { makeStyles, useTheme } from "@mui/styles";
import clsx from "clsx";
import MenuIcon from "@mui/icons-material/Menu";
import PeopleAltOutlined from "@mui/icons-material/PeopleAltOutlined";
import FlareOutlined from "@mui/icons-material/FlareOutlined";
import TouchAppOutlined from "@mui/icons-material/TouchAppOutlined";
import VpnLockOutlined from "@mui/icons-material/VpnLockOutlined";
import CategoryIcon from "@mui/icons-material/Category";

import ConnectButton from "./ConnectButton";
import { useMetamask } from "../useMetamask";
import { getUserAddress } from "../actions/web3Actions";
import constants from "../utils/constants";
import Leftbar from "./Leftbar";
const useStyles = makeStyles((theme) => ({
  appBarBackground: {
    boxShadow: "none",
    backgroundColor: "transparent",
    width: "100%",
    paddingRight: "12px",
  },
  list: {
    paddingTop: 20,
    width: "250px",
    borderLeft: "5px solid pink",
    borderColor: "#3A1242",
    height: "100%",
    backgroundColor: "#100525",
  },
  logoText: {
    display: "flex",
    justifyContent: "center",
    color: "white",
    alignItems: "center",
    fontWeight: 600,
  },
  highlight: {
    fontWeight: 600,
    color: "#C9FD42",
  },
}));

const Navbar = ({ page, setPage }) => {
  const theme = useTheme();
  const classes = useStyles();
  const [drawer, setDrawer] = React.useState(false);
  const [state, setState] = React.useState({
    right: false,
  });

  const { active, connectMetamask, tried, account } = useMetamask();

  useEffect(() => {
    async function asyncFn() {
      //Events to detect changes in account or network.
      if (window.ethereum !== undefined) {
        window.ethereum.on("accountsChanged", async function (accounts) {
          window.location.reload();
        });

        window.ethereum.on("chainChanged", async function (networkId) {
          window.location.reload();
        });
      }
    }
    asyncFn();
  }, [active]);

  useEffect(() => {
    async function asyncFn() {
      //Events to detect changes in account or network.
      if (window.ethereum !== undefined) {
        await window.ethereum.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: constants.net === 1 ? "0x13881" : "0x89" }], // chainId must be in hexadecimal numbers
        });
      }
    }
    asyncFn();
  }, [active]);

  return (
    <div style={{ width: "100%" }}>
      <AppBar
        color="transparent"
        position="static"
        style={{
          boxShadow: "none",
          backgroundColor: "transparent",
          width: "100%",
        }}
      >
        <Toolbar
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "stretch",
            justifyContent: "space-between",
            [theme.breakpoints.up("md")]: {
              display: "none",
            },
          }}
        >
          <div className="d-flex justify-content-center align-items-center">
            <Avatar
              variant="square"
              src="https://assets.coingecko.com/coins/images/19696/large/Thumbnail_-_500_px_-_Black.png?1635751681"
              style={{ height: 60, width: 75 }}
            />
          </div>
          <Toolbar
            style={{
              marginLeft: 10,
              marginRight: 10,
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              [theme.breakpoints.down("md")]: {
                marginLeft: 0,
                marginRight: 0,
              },
            }}
          >
            <div className="d-flex justify-content-end align-items-center">
              <ConnectButton />
            </div>
          </Toolbar>
          <div>
            {["right"].map((anchor) => (
              <React.Fragment key={anchor}>
                <IconButton
                  aria-label="Menu"
                  aria-haspopup="true"
                  style={{
                    color: "#212121",
                    marginTop: "10px",
                    marginRight: "-15px",
                    position: "relative",
                    zIndex: 10001,
                  }}
                  onClick={() => setDrawer(!drawer)}
                >
                  <MenuIcon style={{ color: "#fffff" }} />
                </IconButton>

                <SwipeableDrawer
                  disableSwipeToOpen={false}
                  open={drawer}
                  onClose={() => setDrawer(false)}
                  onOpen={() => setDrawer(true)}
                  classes={{ paper: classes.appBarBackground }}
                >
                  <Leftbar
                    page={page}
                    setPage={setPage}
                    drawer={drawer}
                    setDrawer={setDrawer}
                  />
                </SwipeableDrawer>
              </React.Fragment>
            ))}
          </div>
        </Toolbar>
        <Toolbar
          sx={{
            marginLeft: 10,
            marginRight: 10,
            width: "90%",
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            [theme.breakpoints.down("md")]: {
              display: "none",
            },
          }}
        >
          <div className="d-flex justify-content-end align-items-center">
            <ConnectButton />
          </div>
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default Navbar;
