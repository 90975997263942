// 0 mainnet, 1 testnet
let network_type = 0;

let constants;
constants = {
  net: network_type,
  chainIdMain: 137,
  chainIdMainInHex: "0x89",
  chainIdTest: 80001,
  chainIdTestInHex: "0x13881",
  dishOwnerAddress: "0xB2b0a3311742C977895a7f89f64a68c8b78C334a",
  vault_contract: "0xA52264dbcbaC140E26072D763D5Ee887Ae869F13",
  marketplace_contract: "0x624239d6980D57810cC12ef3570A40e9E8740beE",
  game_contract: "0xC9A66685370F1930E71Ce2CDd1CBF75cc1dC62e1",
  CIPHER_KEY: "TURKWJCBSFLPIWTWVSNDKDYBSKMDISBB",
  farmingContract: {
    0: "0xd1d25EAc33401b97568869564ee4ba6e259DCB35",
    1: "0xbEa244d7Cbc3BEAaD79Ea00e97b8515Cb8C1916E",
    2: "0x8765330eBe67FAEb3Cf5a2Abd2d7aB1Fa00F83eD",
    3: "0x0C271f2F3F1ACAD6BA99B5DEBE610cEc391a5d33",
    4: "0xCB39949A7a0A0da883BC584c1f408E4c269F3587",
    5: "0x47f48E1B7e4C3A72C355cb6A3940cf905FE46615",
  },
};

export default constants;
