import axios from "axios";
import constants from "../utils/constants";
import CryptoJS from "crypto-js";

let foodTruckBaseUrl = "https://foodtruck.onerare.io";
let apiUrl = "https://v2.onerare.io/api";
let newApiUrl = "https://api.gobbl.io/api";
// let newApiUrl = "https://testapi.gobbl.io/api";
let testUrl = "https://game.onerare.io/api";

const getCipherText = (inputBodyData) => {
  let secretKey = CryptoJS.enc.Utf8.parse(constants.CIPHER_KEY);
  // Encrypt
  var ciphertext = CryptoJS.AES.encrypt(
    JSON.stringify(inputBodyData),
    secretKey,
    {
      mode: CryptoJS.mode.ECB,
    }
  ).toString();

  return { data: ciphertext.toString() };
};

// Get users ton data
export const getAnalyticsDataTon = async () => {
  let url = `${newApiUrl}/user/getAnalyticsDataTon`;
  let response = await axios
    .get(url)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });

  if (response && !response.error) {
    return response.result;
  } else {
    return 0;
  }
};

// Get users tasks data
export const getAnalyticsDataTasks = async () => {
  let url = `${newApiUrl}/user/getAnalyticsDataTasks`;
  let response = await axios
    .get(url)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });

  if (response && !response.error) {
    return response.result;
  } else {
    return 0;
  }
};

// Get users referral data
export const getAnalyticsDataReferral = async () => {
  let url = `${newApiUrl}/user/getAnalyticsDataReferral`;
  let response = await axios
    .get(url)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });

  if (response && !response.error) {
    return response.result;
  } else {
    return 0;
  }
};

// Get no. of wallet users
export const getAnalyticsDataWallet = async () => {
  let url = `${newApiUrl}/user/getAnalyticsDataWallet`;
  let response = await axios
    .get(url)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });

  if (response && !response.error) {
    return response.result;
  } else {
    return 0;
  }
};

// Get user data by user id
export const getAnalyticsDataByTelegramId = async (userId) => {
  let url = `${newApiUrl}/user/getAnalyticsDataByTelegramId?telegramId=${userId}`;
  let response = await axios
    .get(url)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });

  if (response && !response.error) {
    return response.result;
  } else {
    return 0;
  }
};

// Get Daily Claims And Referrals Data
export const getDailyClaimsAndReferralsData = async () => {
  let url = `${newApiUrl}/restaurant/getDailyClaims`;
  let response = await axios
    .get(url)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });

  if (response && !response.error) {
    return response.result;
  } else {
    return 0;
  }
};
