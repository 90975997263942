import * as React from "react";
import TgAnalytics from "./TgAnalytics";

const Home = () => {
  return (
    <div className="ps-4 ps-md-2">
      <TgAnalytics />
    </div>
  );
};

export default Home;
