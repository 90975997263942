import { useEffect, useMemo, useState } from "react";
import ethersServiceProvider from "./services/ethersServicesProvider";
import Web3 from "web3";
import { useNavigate } from "react-router-dom";

export const useMetamask = () => {
  const navigate = useNavigate();
  const [walletStatus, setWalletStatus] = useState(0);

  useEffect(() => {
    const init = async () => {
      try {
        // 1. Finding Social address in localStorage
        let localStorageAddress = await localStorage.getItem("userAddress");

        if (localStorageAddress != null) {
          await ethersServiceProvider.setCurrentAccount(localStorageAddress);
          setWalletStatus(walletStatus + 1);
        }
      } catch (error) {
        console.error(error);
      }
    };

    init();
  }, []);

  // Subscribe account change event of web3
  useEffect(() => {
    async function asyncFn() {
      //Events to detect changes in account or network.
      if (window.ethereum !== undefined) {
        window.ethereum.on("accountsChanged", async function (accounts) {
          _logoutUserFromMetamask();
        });
      }
    }
    asyncFn();
  }, [ethersServiceProvider.currentAccount]);

  const _account = useMemo(() => {
    let addressData = ethersServiceProvider.currentAccount;
    if (!addressData) {
      return null;
    }
    return addressData;
  }, [ethersServiceProvider.currentAccount, walletStatus]);

  // LOGIN using Metamask
  const _loginWithMetamsk = async () => {
    if (window.ethereum) {
      await window.ethereum.request({ method: "eth_requestAccounts" });
      window.web3 = new Web3(window.ethereum);
      const account = window.web3.eth.accounts;

      //Get the current MetaMask selected/active wallet
      const walletAddress = account.givenProvider.selectedAddress;
      if (walletAddress) {
        await localStorage.setItem("userAddress", walletAddress);
        await ethersServiceProvider.setCurrentAccount(walletAddress);
        navigate("/");
      }
    } else {
      console.log("No wallet");
    }
  };

  // To disconnect from metamask
  const _logoutUserFromMetamask = async () => {
    await localStorage.removeItem("userAddress");
    await ethersServiceProvider.setCurrentAccount(null);

    navigate("/login");
  };

  return {
    accountSC: _account,
    logout: _logoutUserFromMetamask,
    loginWithMetamask: _loginWithMetamsk,
  };
};
